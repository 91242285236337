import request from '@/utils/request'
// /job/sessionJobs
export function sessionJobs() {
  return request({
    url: '/job/sessionJobs',
    method: 'GET',
  })
}
// /job/sessionJobsCount
export function sessionJobsCount() {
  return request({
    url: '/job/sessionJobsCount',
    method: 'GET',
  })
}
// /job/startRecentJob
export function startRecentJob(params) {
  return request({
    url: '/job/startRecentJob',
    method: 'GET',
    params
  })
}